

import { nlToBr } from '@/utils/string';

export default {
  props: {
    color: {
      type:    String,
      default: 'secondary'
    },
    label: {
      type:    [String, Error, Object],
      default: null,
    },
    labelKey: {
      type:    String,
      default: null,
    },
    closable: {
      type:    Boolean,
      default: false,
    }
  },

  methods: { nlToBr },
};
