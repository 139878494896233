import { render, staticRenderFns } from "./WorkloadHealthScale.vue?vue&type=template&id=3f7f8639&scoped=true&"
import script from "./WorkloadHealthScale.vue?vue&type=script&lang=js&"
export * from "./WorkloadHealthScale.vue?vue&type=script&lang=js&"
import style0 from "./WorkloadHealthScale.vue?vue&type=style&index=0&id=3f7f8639&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f7f8639",
  null
  
)

export default component.exports