import { render, staticRenderFns } from "./harvesterhci.io.virtualmachinetemplateversion.vue?vue&type=template&id=45f128a1&"
import script from "./harvesterhci.io.virtualmachinetemplateversion.vue?vue&type=script&lang=js&"
export * from "./harvesterhci.io.virtualmachinetemplateversion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports